<template>
  <div class="change-password-container">
    <el-card class="change-password-card">
      <h2 class="change-password-title">修改密码</h2>
      <el-form :model="form" :rules="rules" ref="changePasswordForm" @submit.native.prevent="changePassword">
        <!-- 原始密码 -->
        <el-form-item label="原始密码" prop="oldPassword">
          <el-input
            v-model="form.oldPassword"
            type="password"
            placeholder="请输入原始密码"
            show-password
          ></el-input>
        </el-form-item>

        <!-- 新密码 -->
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            type="password"
            placeholder="请输入新密码"
            show-password
          ></el-input>
        </el-form-item>

        <!-- 确认密码 -->
        <el-form-item label="确认密码" prop="newPassword2">
          <el-input
            v-model="form.newPassword2"
            type="password"
            placeholder="请再次输入新密码"
            show-password
          ></el-input>
        </el-form-item>

        <!-- 提交按钮 -->
        <el-form-item>
          <el-button type="primary" native-type="submit" class="submit-button">修改密码</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    // 自定义密码一致性验证规则
    const validatePassword2 = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };

    return {
      form: {
        oldPassword: '', // 原始密码
        newPassword: '', // 新密码
        newPassword2: '', // 确认密码
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入原始密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能少于6位', trigger: 'blur' }
        ],
        newPassword2: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: validatePassword2, trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    async changePassword() {
      try {
        // 表单验证
        await this.$refs.changePasswordForm.validate();

        // 发送修改密码请求
        const response = await fetch('/api/change-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            oldPassword: this.form.oldPassword,
            newPassword: this.form.newPassword,
            newPassword2: this.form.newPassword2,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          // 密码修改成功
          this.$message({
            message: data.message || '密码修改成功',
            type: 'success',
            duration: 2000, // 2秒后自动关闭
          });

          // 2秒后跳转到登录页面
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
        } else {
          // 密码修改失败
          this.$message({
            message: data.message || '密码修改失败',
            type: 'error',
          });
        }
      } catch (err) {
        console.error('密码修改请求失败:', err);
        this.$message({
          message: '服务器错误，请稍后重试',
          type: 'error',
        });
      }
    },
  },
};
</script>

<style scoped>
.change-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.change-password-card {
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.change-password-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #303133;
}

.submit-button {
  width: 100%;
}
</style>