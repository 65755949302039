import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import Materials from '../views/Materials.vue';
import Simulation from '../views/Simulation.vue';
import Reagents from '../views/Reagents.vue';
import Equipment from '../views/Equipment.vue';
import Graphics from '../views/Graphics.vue';
import About from '../views/About.vue';
import Login from '../views/Login.vue';

// 后台
import Admin from '@/views/Admin.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',  // 使用 HTML5 history 模式，不使用 #
  routes: [
    // 可对外展示界面
    { path: '/', name: 'Home', component: Home },
    { path: '/materials', name: 'Materials', component: Materials },
    { path: '/simulation', name: 'Simulation', component: Simulation },
    { path: '/reagents', name: 'Reagents', component: Reagents },
    { path: '/equipment', name: 'Equipment', component: Equipment },
    { path: '/graphics', name: 'Graphics', component: Graphics },
    { path: '/about', name: 'About', component: About },
    { path: '/login', name: 'Login', component: Login },
    // 后端界面
    { path: '/admin', name: 'Admin', component: Admin, meta: { requiresAuth: true } },
  ],
});

// 路由守卫：检查用户是否登录
router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn'); // 假设登录状态存储在 localStorage
  if (to.path === '/admin' && !isLoggedIn) {
    // 如果路由需要登录且用户未登录，跳转到登录页面
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else {
    // 否则继续导航
    next();
  }
});

export default router;