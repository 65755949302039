<template>
    <div class="admin">
      <Sidebar @change-tab="changeTab" />
      <div class="content">
        <UserManagement v-if="currentTab === 'user'" />
        <UploadProduct v-if="currentTab === 'uploadProduct'" />
        <EditProduct v-if="currentTab === 'editProduct'" />
        <UploadSimulation v-if="currentTab === 'uploadSimulation'" />
        <EditSimulation v-if="currentTab === 'editSimulation'" />
        <UploadGraphics v-if="currentTab === 'uploadGraphics'" />
        <EditGraphics v-if="currentTab === 'editGraphics'" />
        <NewTopic v-if="currentTab === 'newTopic'" />
        <EditTopic v-if="currentTab === 'editTopic'" />
      </div>
    </div>
  </template>
  
  <script>
  import Sidebar from '@/components/Sidebar.vue';
  import UserManagement from '@/components/UserManagement.vue';
  import UploadProduct from '@/components/UploadProduct.vue';
  import EditProduct from '@/components/EditProduct.vue';
  import UploadSimulation from '@/components/UploadSimulation.vue';
  import EditSimulation from '@/components/EditSimulation.vue';
  import UploadGraphics from '@/components/UploadGraphics.vue';
  import EditGraphics from '@/components/EditGraphics.vue';
  import NewTopic from '@/components/NewTopic.vue';
  import EditTopic from '@/components/EditTopic.vue';
  
  export default {
    components: {
      Sidebar,
      UserManagement,
      UploadProduct,
      EditProduct,
      UploadSimulation,
      EditSimulation,
      UploadGraphics,
      EditGraphics,
      NewTopic,
      EditTopic
    },
    data() {
      return {
        currentTab: 'user' // 默认显示用户管理
      };
    },
    methods: {
      changeTab(tab) {
        this.currentTab = tab;
      }
    }
  };
  </script>
  
  <style scoped>
  .admin {
    display: flex;
    height: 100vh;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    background-color: #f4f4f4;
    overflow-y: auto;
  }
  </style>