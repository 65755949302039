<template>
  <div class="login-container">
    <el-card class="login-card">
      <h1 class="login-title">登录</h1>
      <el-form :model="form" :rules="rules" ref="loginForm" @submit.native.prevent="login">
        <el-form-item prop="username">
          <el-input v-model="form.username" placeholder="用户名" prefix-icon="el-icon-user" clearable></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" type="password" placeholder="密码" prefix-icon="el-icon-lock" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit" class="login-button">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('/api/login', {
          username: this.form.username,
          password: this.form.password
        });
        // 登录成功后显示成功提示
        this.$message({
          message: '登录成功！',
          type: 'success',
          duration: 2000 // 2秒后自动关闭
        });

        // 登录成功后设置登录状态
        localStorage.setItem('isLoggedIn', true);

        // 跳转到原页面或默认页面
        const redirect = '/admin';
        this.$router.push(redirect);
      } catch (error) {
        // 登录失败后显示错误提示
        this.$message({
          message: '用户名或密码错误',
          type: 'error',
          duration: 2000 // 2秒后自动关闭
        });
      }
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-card {
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #303133;
}

.login-button {
  width: 100%;
}
</style>