import { render, staticRenderFns } from "./UploadProduct.vue?vue&type=template&id=34c33292&scoped=true"
import script from "./UploadProduct.vue?vue&type=script&lang=js"
export * from "./UploadProduct.vue?vue&type=script&lang=js"
import style0 from "./UploadProduct.vue?vue&type=style&index=0&id=34c33292&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c33292",
  null
  
)

export default component.exports