<template>
  <footer class="footer">
    <div class="phone">19821254370、19581416392</div>
    <div>
      邮箱：kelanningtech@163.com | 地址：上海市松江区乐都路358号501室
    </div>
    <div class="social-media">
      <div class="wechat">
        <img src="@/assets/wechat1.png" alt="微信" />
        <span>订阅号</span>
      </div>
      <div class="wechat">
        <img src="@/assets/wechat2.png" alt="企业微信" />
        <span>企业微信</span>
      </div>
    </div>
    <el-divider class="my-divider" horizontal></el-divider>
    <!-- 分开绑定点击事件 -->
    <div class="footer-links">
      <span @click="handleICPClick">沪ICP备2024101358号</span> | 
      <span @click="handleCompanyClick">上海科岚宁科技有限公司</span>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      clickCount: 0, // 点击计数器
    };
  },
  methods: {
    // 处理ICP备案号的点击事件
    handleICPClick() {
      window.open('https://beian.miit.gov.cn/', '_blank');
    },
    // 处理公司名称的点击事件
    handleCompanyClick() {
      this.clickCount++; // 每次点击增加计数器
      if (this.clickCount === 3) {
        // 点击 3 次后跳转
        this.$router.push('/login').then(() => {
          // 跳转完成后滚动到顶部
          window.scrollTo(0, 0);
        });
        this.clickCount = 0; // 重置计数器
      }
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #00102e;
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 14px;
  border-top: 1px solid #ccc;
}
/* 增大电话字体 */
.phone {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.wechat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wechat img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}
.my-divider {
  background-color: rgba(255, 255, 255, 0.4); /* 例如: #ff0000 */
  height: 0.1px;
  margin: 5px 0; /* 缩小上下边距 */
}
.footer-links {
  cursor: pointer;
}
.footer-links span {
  margin: 0 5px;
}
</style>