<template>
  <div class="simulation-container">
    <p style="height: 60px;"></p>
    <!-- 动态渲染卡片 -->
    <el-card
      v-for="(item, index) in simulations"
      :key="index"
      class="simulation-card"
      shadow="always"
    >
      <div slot="header" class="clearfix">
        <span>{{ item.name }}</span>
      </div>
      <img
        :src="`${item.image_path}`"
        :alt="item.name"
        class="simulation-image"
      />
      <div class="description">
        {{ item.description || '暂无描述' }}
      </div>
    </el-card>
    <p style="height: 60px;"></p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SimulationCards',
  data() {
    return {
      simulations: [], // 存储从后端获取的数据
    };
  },
  async created() {
    await this.fetchSimulations();
  },
  methods: {
    async fetchSimulations() {
      try {
        const response = await axios.get('/api/productssimulation');
        this.simulations = response.data.data; // 将后端数据赋值给 simulations
      } catch (error) {
        console.error('Failed to fetch simulations:', error);
      }
    },
  },
};
</script>

<style scoped>
.simulation-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.simulation-card {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
}

.simulation-card >>> .el-card__header {
  background-color: #f5f7fa;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.simulation-image {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 15px;
}

.description {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  line-height: 1.6;
  padding: 0 15px 15px;
}
</style>