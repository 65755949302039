<template>
  <div class="graphic-management-container">
    <el-card class="graphic-management-card">
      <h1 class="graphic-management-title">绘图管理</h1>

      <!-- 加载数据按钮 -->
      <el-button type="primary" @click="loadGraphics">加载数据</el-button>

      <!-- 图形表格 -->
      <el-table :data="graphics" style="width: 100%" border>
        <el-table-column prop="name" label="名称" width="180"></el-table-column>
        <el-table-column prop="category" label="类别" width="120"></el-table-column>
        <el-table-column label="图片" width="120">
          <template slot-scope="{ row }">
            <el-image
              style="width: 100px; height: 100px"
              :src="row.image_path"
              :preview-src-list="[row.image_path]"
              fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="{ row }">
            <el-button type="primary" size="small" @click="openEditDialog(row)">修改</el-button>
            <el-button type="danger" size="small" @click="deleteGraphic(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 修改图形的模态框 -->
      <el-dialog :visible.sync="editDialogVisible" title="修改图形" width="30%">
        <el-form :model="editForm" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item label="类别">
            <el-input v-model="editForm.category"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeEditDialog">取消</el-button>
          <el-button type="primary" @click="submitEditForm">保存</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      graphics: [], // 图形数据
      editDialogVisible: false, // 修改模态框是否显示
      editForm: {
        id: null,
        name: '',
        category: '',
      },
    };
  },
  methods: {
    // 加载图形数据
    async loadGraphics() {
      try {
        const response = await axios.get('/api/graphics');
        this.graphics = response.data.map(graphic => ({
          ...graphic,
          image_path: `${graphic.image_path}`,
        }));
      } catch (error) {
        console.error('加载数据失败:', error);
        this.$message.error('加载数据失败，请稍后重试');
      }
    },
    // 打开修改图形的模态框
    openEditDialog(graphic) {
      this.editForm = {
        id: graphic.id,
        name: graphic.name,
        category: graphic.category,
      };
      this.editDialogVisible = true; // 显示模态框
    },
    // 关闭修改图形的模态框
    closeEditDialog() {
      this.editDialogVisible = false; // 隐藏模态框
    },
    // 提交修改表单
    async submitEditForm() {
      try {
        await axios.put(`/api/graphics/${this.editForm.id}`, {
          name: this.editForm.name,
          category: this.editForm.category,
        });
        this.$message.success('图形修改成功');
        this.closeEditDialog();
        this.loadGraphics(); // 重新加载数据
      } catch (error) {
        console.error('修改失败:', error);
        this.$message.error('修改失败，请稍后重试');
      }
    },
    // 删除图形
    async deleteGraphic(id) {
      try {
        await this.$confirm('确定删除该图形吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await axios.delete(`/api/graphics/${id}`);
        this.$message.success('图形删除成功');
        this.loadGraphics(); // 重新加载数据
      } catch (error) {
        console.error('删除失败:', error);
        this.$message.error('删除失败，请稍后重试');
      }
    },
  },
  mounted() {
    // 页面加载时默认加载数据
    this.loadGraphics();
  },
};
</script>

<style scoped>
.graphic-management-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.graphic-management-card {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.graphic-management-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #303133;
}
</style>