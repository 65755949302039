<template>
  <div class="about-us">
    <p style="height: 60px;"></p>
    <el-row :gutter="20" justify="space-between">
      <!-- 左侧联系方式与公司介绍 -->
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <el-card shadow="hover">
          <h2>联系方式</h2>
          <el-divider></el-divider>
          <ul>
            <li><strong>电话：</strong>19821254370、19581416392</li>
            <li><strong>微信：</strong>Kelan_n</li>
            <li><strong>邮箱：</strong>kelanningtech@163.com</li>
            <li><strong>地址：</strong>上海市松江区乐都路358号501室</li>
          </ul>
          <el-divider></el-divider>
          <h2>公司介绍</h2>
          <el-divider></el-divider>
          <p>
            我们公司致力于为客户提供高效、创新的技术解决方案。我们的团队拥有丰富的行业经验，能够满足不同行业客户的需求。
            我们秉承着“质量第一，客户至上”的理念，努力推动技术进步与服务创新。
          </p>
          <p>
            上海科岚宁科技有限公司
          </p>
        </el-card>
      </el-col>

      <!-- 右侧公司位置 -->
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <el-card shadow="hover">
          <h2>公司位置</h2>
          <el-divider></el-divider>
          <div id="map" style="width: 100%; height: 400px;"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// 引入高德地图API
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  name: "AboutUs",
  mounted() {
    // 在组件挂载后加载高德地图
    this.initMap();
  },
  methods: {
    initMap() {
      // 加载高德地图API
      AMapLoader.load({
        key: '748f86a169f27b6d60d019d4f52c2290', // 替换为你自己的高德API密钥
        version: '2.0',
        plugins: ['AMap.Marker']
      }).then(AMap => {
        // 创建地图实例
        const map = new AMap.Map('map', {
          resizeEnable: true,
          center: [121.2400, 31.0300], // 设置地图中心点为上海松江区
          zoom: 8 // 提高地图缩放等级
        });

        // 创建标记
        const marker = new AMap.Marker({
          position: [121.2400, 31.0300],
          title: '乐都路358号501室'
        });

        map.add(marker);
      }).catch(e => {
        console.error('地图加载失败', e);
      });
    }
  }
};
</script>

<style scoped>
.about-us {
  margin: 20px;
  margin-top: 20px;
}

.el-row {
  flex: 1;
}

.el-col {
  display: flex;
  flex-direction: column;
}

.el-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 500px;  /* 设置固定高度 */
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  margin-bottom: 10px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}

p {
  font-size: 14px;
  line-height: 1.6;
  text-indent: 2em;  /* 设置首行缩进 2em，具体数值可以根据需要调整 */
}


</style>
