<template>
  <div class="upload-simulation-container">
    <el-card class="upload-simulation-card">
      <h1 class="upload-simulation-title">上传模拟计算案例</h1>
      <el-form :model="form" :rules="rules" ref="uploadForm" @submit.native.prevent="submitForm">
        <!-- 专题选择框 -->
        <el-form-item label="专题" prop="topic">
          <el-select v-model="form.topic" placeholder="请选择专题" clearable>
            <el-option v-for="topic in topics" :key="topic" :label="topic" :value="topic"></el-option>
          </el-select>
        </el-form-item>

        <!-- 名称文本框 -->
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入模拟计算案例名称"></el-input>
        </el-form-item>

        <!-- 描述文本框 -->
        <el-form-item label="描述" prop="description">
          <el-input
            type="textarea"
            v-model="form.description"
            placeholder="请输入模拟计算案例描述"
          ></el-input>
        </el-form-item>

        <!-- 图片上传 -->
        <el-form-item label="图片" prop="image">
          <el-upload
            action="#"
            :auto-upload="false"
            :on-change="handleImageUpload"
            :before-upload="beforeImageUpload"
            :show-file-list="false"
          >
            <el-button type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只支持上传 .webp 格式的图片。如果您的图片是 .jpg 或 .png 格式，请使用
              <a
                href="https://bigconvert.11zon.com/zh-cn/image-to-webp"
                target="_blank"
                rel="noopener noreferrer"
              >
                此工具
              </a>
              进行转换。
            </div>
          </el-upload>
          <div v-if="imagePreview" class="image-preview">
            <img :src="imagePreview" alt="模拟计算案例图片预览" />
          </div>
        </el-form-item>

        <!-- 提交按钮 -->
        <el-form-item>
          <el-button type="primary" native-type="submit">提交</el-button>
        </el-form-item>

        <!-- 错误提示 -->
        <el-alert
          v-if="error"
          :title="error"
          type="error"
          show-icon
          class="error-alert"
        ></el-alert>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        topic: '', // 专题
        name: '', // 名称
        description: '', // 描述
        image: null, // 图片文件
      },
      topics: [], // 专题列表
      imagePreview: '', // 图片预览
      error: '', // 错误信息
      rules: {
        topic: [
          { required: true, message: '请选择专题', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    // 处理图片上传
    handleImageUpload(file) {
      this.form.image = file.raw;
      this.imagePreview = URL.createObjectURL(file.raw); // 生成图片预览
      this.error = ''; // 清空错误提示
    },
    // 图片上传前的格式验证
    beforeImageUpload(file) {
      const isWebp = file.type === 'image/webp';
      if (!isWebp) {
        this.$message.error('只支持上传 .webp 格式的图片');
      }
      return isWebp;
    },
    // 获取专题列表
    async fetchTopics() {
      try {
        const response = await axios.get('/api/get-topics2');
        console.log('专题数据:', response.data); // 打印专题数据

        // 假设返回的是 [{ name: "专题1" }, { name: "专题2" }]
        this.topics = response.data.map(item => item.name); // 提取 name 字段
      } catch (error) {
        console.error('获取专题列表失败:', error);
        this.error = '获取专题列表失败，请重试';
      }
    },
    // 提交表单
    async submitForm() {
      try {
        // 表单验证
        await this.$refs.uploadForm.validate();

        // 创建 FormData 对象
        const formData = new FormData();
        formData.append('topic', this.form.topic);
        formData.append('name', this.form.name);
        formData.append('description', this.form.description);
        formData.append('image', this.form.image);

        // 发送请求
        const response = await axios.post('/api/upload-simulation', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // 清空表单
        this.$refs.uploadForm.resetFields();
        this.imagePreview = '';
        this.error = '';

        this.$message.success('模拟计算案例上传成功！');
      } catch (error) {
        this.error = '模拟计算案例上传失败，请重试';
        console.error('上传失败:', error);
      }
    },
  },
  mounted() {
    this.fetchTopics(); // 页面加载时获取专题列表
  },
};
</script>

<style scoped>
.upload-simulation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.upload-simulation-card {
  width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.upload-simulation-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #303133;
}

.image-preview {
  margin-top: 10px;
}

.image-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.error-alert {
  margin-top: 20px;
}
</style>