<template>
  <div class="upload-product-container">
    <el-card class="upload-product-card">
      <h1 class="upload-product-title">上传商品</h1>
      <el-form :model="form" :rules="rules" ref="uploadForm" @submit.native.prevent="submitForm">
        <!-- 栏目选择框 -->
        <el-form-item label="栏目" prop="category">
          <el-select
            v-model="form.category"
            placeholder="请选择栏目"
            @change="fetchTopics"
            clearable
          >
            <el-option label="材料测试" value="material_testing_type"></el-option>
            <el-option label="试剂耗材" value="reagents_type"></el-option>
            <el-option label="仪器设备" value="instruments_type"></el-option>
          </el-select>
        </el-form-item>

        <!-- 专题选择框 -->
        <el-form-item label="专题" prop="topic">
          <el-select
            v-model="form.topic"
            placeholder="请选择专题"
            :disabled="!form.category"
            @change="handleTopicChange"
            clearable
          >
            <el-option
              v-for="topic in topics"
              :key="topic"
              :label="topic"
              :value="topic"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 名称文本框 -->
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>

        <!-- 原始价格文本框 -->
        <el-form-item label="原始价格" prop="originalPrice">
          <el-input
            v-model="form.originalPrice"
            placeholder="请输入原始价格"
          ></el-input>
        </el-form-item>

        <!-- 出售价格文本框 -->
        <el-form-item label="出售价格" prop="currentPrice">
          <el-input
            v-model="form.currentPrice"
            placeholder="请输入出售价格"
          ></el-input>
        </el-form-item>

        <!-- 图片上传 -->
        <el-form-item label="图片" prop="image">
          <el-upload
            action="#"
            :auto-upload="false"
            :on-change="handleImageUpload"
            :before-upload="beforeImageUpload"
            :show-file-list="false"
          >
            <el-button type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只支持上传 .webp 格式的图片。如果您的图片是 .jpg 或 .png 格式，请使用
              <a
                href="https://bigconvert.11zon.com/zh-cn/image-to-webp"
                target="_blank"
                rel="noopener noreferrer"
              >
                此工具
              </a>
              进行转换。
            </div>
          </el-upload>
          <div v-if="imagePreview" class="image-preview">
            <img :src="imagePreview" alt="商品图片预览" />
          </div>
        </el-form-item>

        <!-- 提交按钮 -->
        <el-form-item>
          <el-button type="primary" native-type="submit">提交</el-button>
        </el-form-item>

        <!-- 错误提示 -->
        <el-alert
          v-if="error"
          :title="error"
          type="error"
          show-icon
          class="error-alert"
        ></el-alert>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        category: '', // 栏目
        topic: '', // 专题
        name: '', // 名称
        originalPrice: '', // 原始价格
        currentPrice: '', // 出售价格
        image: null, // 图片文件
      },
      topics: [], // 专题列表
      imagePreview: '', // 图片预览
      error: '', // 错误信息
      rules: {
        category: [
          { required: true, message: '请选择栏目', trigger: 'change' }
        ],
        topic: [
          { required: true, message: '请选择专题', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        originalPrice: [
          { required: true, message: '请输入原始价格', trigger: 'blur' }
        ],
        currentPrice: [
          { required: true, message: '请输入出售价格', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    // 处理图片上传
    handleImageUpload(file) {
      this.form.image = file.raw;
      this.imagePreview = URL.createObjectURL(file.raw); // 生成图片预览
      this.error = ''; // 清空错误提示
    },
    // 图片上传前的格式验证
    beforeImageUpload(file) {
      const isWebp = file.type === 'image/webp';
      if (!isWebp) {
        this.$message.error('只支持上传 .webp 格式的图片');
      }
      return isWebp;
    },
    // 获取专题列表
    async fetchTopics() {
      if (!this.form.category) {
        this.topics = [];
        this.form.topic = ''; // 清空专题选择
        return;
      }

      try {
        const response = await axios.get('/api/get-topics', {
          params: {
            category: this.form.category,
          },
        });
        this.topics = response.data.topics;
      } catch (error) {
        console.error('获取专题列表失败:', error);
        this.error = '获取专题列表失败，请重试';
      }
    },
    // 处理专题选择框的变化
    handleTopicChange(value) {
      console.log('选中的专题:', value); // 打印选中的专题
      console.log('当前表单数据:', this.form); // 打印当前表单数据
    },
    // 提交表单
    async submitForm() {
      try {
        // 表单验证
        await this.$refs.uploadForm.validate();

        // 打印当前表单数据，用于调试
        console.log('提交前的表单数据:', {
          category: this.form.category,
          topic: this.form.topic, // 确保 topic 的值正确
          name: this.form.name,
          originalPrice: this.form.originalPrice,
          currentPrice: this.form.currentPrice,
          image: this.form.image,
        });

        // 创建 FormData 对象
        const formData = new FormData();
        formData.append('category', this.form.category);
        formData.append('topic', this.form.topic); // 确保传递的是 topic 的值
        formData.append('name', this.form.name);
        formData.append('originalPrice', this.form.originalPrice);
        formData.append('currentPrice', this.form.currentPrice);
        formData.append('image', this.form.image);

        // 打印 FormData 内容，用于调试
        for (let [key, value] of formData.entries()) {
          console.log(key, value);
        }

        // 发送请求
        const response = await axios.post('/api/upload-product', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // 清空表单
        this.$refs.uploadForm.resetFields();
        this.imagePreview = '';
        this.error = '';

        this.$message.success('商品上传成功！');
      } catch (error) {
        this.error = '商品上传失败，请重试';
        console.error('上传失败:', error);
      }
    },
  },
};
</script>

<style scoped>
.upload-product-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 30px;
  background-color: #f5f5f5;
}

.upload-product-card {
  width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.upload-product-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #303133;
}

.image-preview {
  margin-top: 10px;
}

.image-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.error-alert {
  margin-top: 20px;
}
</style>