<template>
    <div class="sidebar">
      <div class="sidebar-header">
        <h2>后台管理</h2>
        <el-button @click="logout" class="logout-button">退出</el-button>
      </div>
      <ul>
        <li @click="changeTab('uploadProduct')">上传商品</li>
        <li @click="changeTab('editProduct')">修改商品</li>
        <li @click="changeTab('uploadSimulation')">上传模拟计算案例</li>
        <li @click="changeTab('editSimulation')">修改模拟计算案例</li>
        <li @click="changeTab('uploadGraphics')">上传科研绘图</li>
        <li @click="changeTab('editGraphics')">修改科研绘图</li>
        <li @click="changeTab('newTopic')">新建专题</li>
        <li @click="changeTab('editTopic')">修改专题</li>
        <li @click="changeTab('user')">修改密码</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      changeTab(tab) {
        this.$emit('change-tab', tab);
      },
      logout() {
        localStorage.removeItem('isLoggedIn'); // 清除登录状态
        this.$router.push('/login'); // 跳转到登录页面
      }
    }
  };
  </script>
  
  <style scoped>
  .sidebar {
    width: 200px;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    height: 100vh;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .sidebar-header h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .logout-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .logout-button:hover {
    background-color: #c0392b;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
  }
  
  li:hover {
    background-color: #34495e;
  }
  </style>