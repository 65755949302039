<template>
  <div>
    <p style="height: 60px;"></p>
    <!-- 控制宽度的 div -->
    <div style="max-width: 90vw; margin: 0 auto;">
      <div v-for="(topic, index) in topics" :key="index">
        <h2 style="text-align: center; margin-bottom: 20px; font-weight: bold;">{{ topic.title }}</h2>
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :sm="12"
            :md="6"
            v-for="(product, index) in topic.products"
            :key="index"
          >
            <el-card shadow="hover" class="product-card">
              <!-- 添加点击事件，点击时打开图片查看器 -->
              <img
                :src="`${product.image}`"
                :alt="product.name"
                class="product-image"
                @click="openImageViewer(`${product.image}`)"
              />
              <div class="product-info">
                <h3>{{ product.name }}</h3>
                <div class="price-wrapper">
                  <!-- 当原始价格和出售价格都是“面议”时，只显示出售价格 -->
                  <span
                    v-if="product.originalPrice !== '面议' || product.salePrice !== '面议'"
                    class="original-price"
                  >
                    {{ product.originalPrice }}
                  </span>
                  <span class="sale-price">{{ product.salePrice }}</span>
                </div>
                <!-- 如果是促销产品显示标签 -->
                <span v-if="product.isOnSale" class="sale-label">促销</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    
    <!-- 图片查看器，放在根元素之外 -->
    <el-dialog :visible.sync="imageViewerVisible" width="60%" :before-close="handleClose">
      <img :src="currentImage" class="full-image" />
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      topics: [],
      imageViewerVisible: false,
      currentImage: '',
    };
  },
  async created() {
    await this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get('/api/productsmaterial');
        const products = response.data.data;
        const categories = [...new Set(products.map(product => product.category))];
        this.topics = categories.map(category => ({
          title: category,
          products: products.filter(product => product.category === category).map(product => ({
            name: product.name,
            originalPrice: product.original_price,
            salePrice: product.sell_price,
            image: product.image_path,
            isOnSale: true,
          })),
        }));
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    },
    openImageViewer(image) {
      this.currentImage = image;
      this.imageViewerVisible = true;
    },
    handleClose(done) {
      this.imageViewerVisible = false;
      done();
    },
  },
};
</script>

<style scoped>
/* 保持原有的样式不变 */
.product-card {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.product-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
  cursor: pointer;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.product-info h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.price-wrapper {
  display: flex;
  align-items: center;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 5px;
}

.sale-price {
  color: #e74c3c;
  font-weight: bold;
  font-size: 24px;
}

.sale-label {
  background-color: #e74c3c;
  color: #fff;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 4px;
  margin-top: 10px;
}

.full-image {
  width: 100%;
  height: auto;
}
</style>