<template>
  <div class="create-topic-container">
    <el-card class="create-topic-card">
      <h1 class="create-topic-title">新建专题</h1>
      <el-form :model="form" :rules="rules" ref="createTopicForm" @submit.native.prevent="submitForm">
        <!-- 类别选择框 -->
        <el-form-item label="类别" prop="category">
          <el-select v-model="form.category" placeholder="请选择类别" clearable>
            <el-option label="仪器设备" value="instruments_type"></el-option>
            <el-option label="材料测试" value="material_testing_type"></el-option>
            <el-option label="试剂耗材" value="reagents_type"></el-option>
            <el-option label="模拟计算" value="simulation_type"></el-option>
            <el-option label="科研绘图" value="graph_type"></el-option>
          </el-select>
        </el-form-item>

        <!-- 专题名称输入框 -->
        <el-form-item label="专题名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入专题名称"></el-input>
        </el-form-item>

        <!-- 提交按钮 -->
        <el-form-item>
          <el-button type="primary" native-type="submit">确认</el-button>
        </el-form-item>

        <!-- 错误提示 -->
        <el-alert
          v-if="error"
          :title="error"
          type="error"
          show-icon
          class="error-alert"
        ></el-alert>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        category: '', // 类别
        name: '', // 专题名称
      },
      error: '', // 错误信息
      rules: {
        category: [
          { required: true, message: '请选择类别', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入专题名称', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    // 提交表单
    async submitForm() {
      try {
        // 表单验证
        await this.$refs.createTopicForm.validate();

        // 发送请求
        const response = await axios.post('/api/create-topic', {
          category: this.form.category,
          name: this.form.name,
        });

        // 清空表单
        this.$refs.createTopicForm.resetFields();
        this.error = '';

        this.$message.success('专题创建成功！');
      } catch (error) {
        console.error('创建失败:', error);
        this.error = '专题创建失败，请稍后重试';
      }
    },
  },
};
</script>

<style scoped>
.create-topic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.create-topic-card {
  width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.create-topic-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #303133;
}

.error-alert {
  margin-top: 20px;
}
</style>