<template>
  <div class="product-management-container">
    <el-card class="product-management-card">
      <h1 class="product-management-title">商品管理</h1>

      <!-- 栏目选择 -->
      <el-form :inline="true" class="filter-form">
        <el-form-item label="选择栏目">
          <el-select v-model="selectedCategory" placeholder="请选择栏目">
            <el-option label="仪器设备" value="instruments"></el-option>
            <el-option label="材料测试" value="material"></el-option>
            <el-option label="试剂耗材" value="reagents"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loadProducts">加载数据</el-button>
        </el-form-item>
      </el-form>

      <!-- 商品表格 -->
      <el-table :data="products" style="width: 100%" border>
        <el-table-column prop="name" label="名称" width="180"></el-table-column>
        <el-table-column prop="category" label="类别" width="120"></el-table-column>
        <el-table-column prop="original_price" label="原价" width="120"></el-table-column>
        <el-table-column prop="sell_price" label="售价" width="120"></el-table-column>
        <el-table-column label="图片" width="120">
          <template slot-scope="{ row }">
            <el-image
              style="width: 100px; height: 100px"
              :src="row.image_path"
              :preview-src-list="[row.image_path]"
              fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="{ row }">
            <el-button type="primary" size="small" @click="openEditDialog(row)">修改</el-button>
            <el-button type="danger" size="small" @click="deleteProduct(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 修改商品的模态框 -->
      <el-dialog :visible.sync="editDialogVisible" title="修改商品" width="30%">
        <el-form :model="editForm" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item label="原价">
            <el-input v-model="editForm.originalPrice"></el-input>
          </el-form-item>
          <el-form-item label="售价">
            <el-input v-model="editForm.sellPrice"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeEditDialog">取消</el-button>
          <el-button type="primary" @click="submitEditForm">保存</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      selectedCategory: 'instruments', // 默认选择的栏目
      products: [], // 商品数据
      editDialogVisible: false, // 修改模态框是否显示
      editForm: {
        id: null,
        name: '',
        originalPrice: '',
        sellPrice: '',
      },
    };
  },
  methods: {
    // 加载商品数据
    async loadProducts() {
      try {
        const response = await axios.get('/api/products', {
          params: {
            category: this.selectedCategory,
          },
        });
        this.products = response.data.data.map(product => ({
          ...product,
          image_path: `${product.image_path}`,
        }));
        console.log('商品数据加载成功:', this.products); // 打印商品数据
      } catch (error) {
        console.error('加载数据失败:', error);
        this.$message.error('加载数据失败，请稍后重试');
      }
    },
    // 打开修改商品的模态框
    openEditDialog(product) {
      console.log('打开修改模态框，当前商品数据:', product); // 打印当前商品数据
      this.editForm = {
        id: product.id,
        name: product.name,
        originalPrice: product.original_price,
        sellPrice: product.sell_price,
      };
      this.editDialogVisible = true; // 显示模态框
    },
    // 关闭修改商品的模态框
    closeEditDialog() {
      this.editDialogVisible = false; // 隐藏模态框
    },
    // 提交修改表单
    async submitEditForm() {
      try {
        await axios.put(`http://81.70.99.105:3000/api/products/${this.editForm.id}`, {
          category: this.selectedCategory,
          name: this.editForm.name,
          originalPrice: this.editForm.originalPrice,
          sellPrice: this.editForm.sellPrice,
        });
        this.$message.success('商品修改成功');
        this.closeEditDialog();
        this.loadProducts(); // 重新加载数据
      } catch (error) {
        console.error('修改失败:', error);
        this.$message.error('修改失败，请稍后重试');
      }
    },
    // 删除商品
    async deleteProduct(id) {
      try {
        await this.$confirm('确定删除该商品吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await axios.delete(`http://81.70.99.105:3000/api/products/${id}`, {
          params: { category: this.selectedCategory },
        });
        this.$message.success('商品删除成功');
        this.loadProducts(); // 重新加载数据
      } catch (error) {
        console.error('删除失败:', error);
        this.$message.error('删除失败，请稍后重试');
      }
    },
  },
  mounted() {
    // 页面加载时默认加载数据
    this.loadProducts();
  },
};
</script>

<style scoped>
.product-management-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.product-management-card {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.product-management-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #303133;
}

.filter-form {
  margin-bottom: 20px;
}
</style>