<template>
  <div class="add-graphic-container">
    <el-card class="add-graphic-card">
      <h1 class="add-graphic-title">新增图形</h1>
      <el-form :model="form" :rules="rules" ref="addGraphicForm" @submit.native.prevent="submitForm">
        <!-- 名称输入框 -->
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入图形名称"></el-input>
        </el-form-item>

        <!-- 类别选择框 -->
        <el-form-item label="类别" prop="category">
          <el-select v-model="form.category" placeholder="请选择类别" clearable>
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.name"
              :value="category.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 图片上传 -->
        <el-form-item label="图片" prop="image">
          <el-upload
            action="#"
            :auto-upload="false"
            :on-change="handleImageUpload"
            :before-upload="beforeImageUpload"
            :show-file-list="false"
          >
            <el-button type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只支持上传 .webp 格式的图片。如果您的图片是 .jpg 或 .png 格式，请使用
              <a
                href="https://bigconvert.11zon.com/zh-cn/image-to-webp"
                target="_blank"
                rel="noopener noreferrer"
              >
                此工具
              </a>
              进行转换。
            </div>
          </el-upload>
        </el-form-item>

        <!-- 提交按钮 -->
        <el-form-item>
          <el-button type="primary" native-type="submit">提交</el-button>
        </el-form-item>

        <!-- 错误提示 -->
        <el-alert
          v-if="error"
          :title="error"
          type="error"
          show-icon
          class="error-alert"
        ></el-alert>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        name: '', // 名称
        category: '', // 类别
        image: null, // 图片文件
      },
      categories: [], // 类别列表
      error: '', // 错误信息
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择类别', trigger: 'change' }
        ],
        image: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    // 处理图片上传
    handleImageUpload(file) {
      this.form.image = file.raw;
      this.error = ''; // 清空错误提示
    },
    // 图片上传前的格式验证
    beforeImageUpload(file) {
      const isWebp = file.type === 'image/webp';
      if (!isWebp) {
        this.$message.error('只支持上传 .webp 格式的图片');
      }
      return isWebp;
    },
    // 提交表单
    async submitForm() {
      try {
        // 表单验证
        await this.$refs.addGraphicForm.validate();

        // 创建 FormData 对象
        const formData = new FormData();
        formData.append('name', this.form.name);
        formData.append('category', this.form.category);
        formData.append('image', this.form.image);

        // 发送请求
        const response = await axios.post('/api/graphics', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // 清空表单
        this.$refs.addGraphicForm.resetFields();
        this.error = '';

        this.$message.success('图形新增成功！');
      } catch (error) {
        console.error('新增失败:', error);
        this.error = '图形新增失败，请稍后重试';
      }
    },
    // 获取类别列表
    async fetchCategories() {
      try {
        const response = await axios.get('/api/graph-types');
        this.categories = response.data;
      } catch (error) {
        console.error('获取类别列表失败:', error);
        this.error = '获取类别列表失败，请稍后重试';
      }
    },
  },
  mounted() {
    // 页面加载时获取类别列表
    this.fetchCategories();
  },
};
</script>

<style scoped>
.add-graphic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.add-graphic-card {
  width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.add-graphic-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #303133;
}

.error-alert {
  margin-top: 20px;
}
</style>