<template>
  <div id="app">
    <!-- 仅在前端路径显示 Header 和 Footer -->
    <Header v-if="isFrontendRoute" />
    <router-view />
    <Footer v-if="isFrontendRoute" />
    <BackToTopAndContact />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import BackToTopAndContact from './components/BackToTopAndContact.vue';

export default {
  components: {
    Header,
    Footer,
    BackToTopAndContact,
  },
  computed: {
    // 判断当前路径是否为前端路径
    isFrontendRoute() {
      const backendPaths = ['/admin']; // 后端路径列表
      return !backendPaths.includes(this.$route.path);
    }
  }
};
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
/* 针对所有滚动条 */
::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
</style>