<template>
  <div class="edit-topic-container">
    <el-card class="edit-topic-card">
      <h1 class="edit-topic-title">修改专题</h1>

      <!-- 栏目选择框 -->
      <el-form :inline="true" class="filter-form">
        <el-form-item label="选择栏目">
          <el-select v-model="selectedCategory" placeholder="请选择栏目" @change="loadTopics" clearable>
            <el-option label="仪器设备" value="instruments_type"></el-option>
            <el-option label="材料测试" value="material_testing_type"></el-option>
            <el-option label="试剂耗材" value="reagents_type"></el-option>
            <el-option label="模拟计算" value="simulation_type"></el-option>
            <el-option label="科研绘图" value="graph_type"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <!-- 专题数据表格 -->
      <el-table :data="topics" style="width: 100%" border v-loading="loading">
        <el-table-column prop="id" label="ID" width="100"></el-table-column>
        <el-table-column label="名称">
          <template slot-scope="{ row }">
            <el-input v-if="row.isEditing" v-model="row.editedName"></el-input>
            <span v-else>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="{ row }">
            <el-button v-if="row.isEditing" type="primary" size="small" @click="saveTopic(row)">保存</el-button>
            <el-button v-else type="primary" size="small" @click="startEdit(row)">修改</el-button>
            <el-button type="danger" size="small" @click="deleteTopic(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 提示信息 -->
      <el-alert
        v-if="error"
        :title="error"
        type="error"
        show-icon
        class="error-alert"
      ></el-alert>
      <p v-if="topics.length === 0 && !loading" class="no-data">暂无专题数据</p>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      selectedCategory: '', // 当前选择的栏目
      topics: [], // 专题数据
      loading: false, // 加载状态
      error: '', // 错误信息
    };
  },
  methods: {
    // 加载专题数据
    async loadTopics() {
      if (!this.selectedCategory) {
        this.topics = [];
        return;
      }

      this.loading = true;
      this.error = '';

      try {
        const response = await axios.get('/api/get-topics3', {
          params: { category: this.selectedCategory },
        });

        console.log('后端返回的数据:', response.data); // 打印后端返回的数据

        // 从 response.data 中获取专题数据
        const topicsArray = response.data || [];

        // 将返回的数据转换为对象数组，并初始化编辑状态
        this.topics = topicsArray.map(topic => ({
          id: topic.id, // 使用数据库中的 id
          name: topic.name,
          isEditing: false,
          editedName: topic.name,
        }));
      } catch (error) {
        console.error('加载专题数据失败:', error);
        this.error = '加载专题数据失败，请稍后重试';
      } finally {
        this.loading = false;
      }
    },
    // 开始编辑
    startEdit(topic) {
      topic.isEditing = true;
    },
    // 保存修改
    async saveTopic(topic) {
      try {
        const response = await axios.put(`/api/update-topic/${topic.id}`, {
          category: this.selectedCategory,
          name: topic.editedName,
        });

        // 更新本地数据
        topic.name = topic.editedName;
        topic.isEditing = false;

        this.$message.success('专题修改成功！');
      } catch (error) {
        console.error('修改失败:', error);
        this.$message.error('专题修改失败，请稍后重试');
      }
    },
    // 删除专题
    async deleteTopic(id) {
      try {
        await this.$confirm('确定删除该专题吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });

        await axios.delete(`/api/delete-topic/${id}`, {
          params: { category: this.selectedCategory },
        });

        // 重新加载数据
        this.loadTopics();
        this.$message.success('专题删除成功！');
      } catch (error) {
        console.error('删除失败:', error);
        this.$message.error('专题删除失败，请稍后重试');
      }
    },
  },
};
</script>

<style scoped>
.edit-topic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.edit-topic-card {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.edit-topic-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #303133;
}

.filter-form {
  margin-bottom: 20px;
}

.error-alert {
  margin-top: 20px;
}

.no-data {
  text-align: center;
  margin-top: 20px;
  color: #666;
}
</style>